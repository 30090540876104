import React, { useState } from 'react';
import FadeUpComponent from './Animation/FadeUpComponent';

const CareerInfo = () => {
  const [candidateCode, setCandidateCode] = useState(1001);
  const [submitStatus, setSubmitStatus] = useState('');

  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmitStatus('Submitting...');
    const formData = new FormData(event.target);

    formData.append("access_key", "f5e93ecd-25e2-4498-b72e-c8c5aaef2655");
    formData.append("candidateCode", candidateCode);

    // Add email subject and recipient
    formData.append("subject", "New Career Application Submission");
    formData.append("to", "contact@resolutecorp.in");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: json
      }).then((res) => res.json());

      if (res.success) {
        console.log("Success", res);
        event.target.reset();
        setCandidateCode(candidateCode + 1);
        setSubmitStatus('Form submitted successfully. An email has been sent.');
      } else {
        setSubmitStatus('Submission failed. Please try again.');
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitStatus('An error occurred. Please try again later.');
    }
  };

  return (
    <section className='max-w-[1200px] m-auto px-8 py-4'>
      <h2 className='text-center items-center text-3xl font-semibold py-4'>
        <FadeUpComponent>Career Info</FadeUpComponent>
      </h2>
      <p className='pb-2'>Fill in the below details or email to contact@resolutecorp.in</p>
      <form onSubmit={onSubmit}>
        <div className='grid md:grid-cols-2 gap-5'>
          <div className='flex flex-col'>
            <label htmlFor="candidateCode" className='mb-1'>Candidate Code*</label>
            <input type="text" readOnly name="candidateCode" id="candidateCode" value={candidateCode} className='border border-[#0A235C] px-2 py-1' />
          </div>
          <div className='flex flex-col'>
            <label htmlFor="candidateType" className='mb-1'>Candidate Type*</label>
            <select id='candidateType' name='candidateType' className='border border-[#0A235C] px-2 py-1' required>
              <option value="">—Select Candidate Type—</option>
              <option value="Exp">Experience</option>
              <option value="Fresher">Fresher</option>
            </select>
          </div>
          <div className='flex flex-col'>
            <input type="text" id="candidateName" name="candidateName" placeholder='Candidate Name*' required className='border border-[#0A235C] px-2 py-1' />
          </div>
          <div className='flex flex-col'>
            <input type="text" id="candidateLastName" placeholder='Candidate Last Name*' name="candidateLastName" required className='border border-[#0A235C] px-2 py-1' />
          </div>
          <div className='flex flex-col'>
            <input type="number" id="age" name='age' placeholder='Age*' required className='border border-[#0A235C] px-2 py-1' />
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-5 py-4'>
          <div className='flex flex-col'>
            <label htmlFor="birthDate" className='mb-1'>Birth Date*</label>
            <input type="date" name="birthDate" id="birthDate" required className='border border-[#0A235C] px-2 py-1'/>
          </div>
          <div className='flex flex-col'>
            <label className='mb-1'>Gender*</label>
            <div className='flex flex-wrap gap-4'>
              <div className='flex items-center'>
                <input type="radio" name="gender" id="male" value="Male" required className='border border-[#0A235C] px-2 py-1'/>
                <label htmlFor="male" className='ml-2'>Male</label>
              </div>
              <div className='flex items-center'>
                <input type="radio" name="gender" id="female" value="Female" required className='border border-[#0A235C] px-2 py-1'/>
                <label htmlFor="female" className='ml-2'>Female</label>
              </div>
              <div className='flex items-center'>
                <input type="radio" name="gender" id="other" value="Other" required className='border border-[#0A235C] px-2 py-1'/>
                <label htmlFor="other" className='ml-2'>Other</label>
              </div>
            </div>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-5 py-4'>
          <div className='flex flex-col'>
            <label htmlFor="fileInput" className='mb-1'>Resume File*</label>
            <input type="file" name="fileInput" id="fileInput" required className='pt-2'/>
          </div>
          <div className='flex flex-col'>
            <input type="email" id="email" name="email" placeholder='E-mail*' required className='border border-[#0A235C] px-2 py-1' />
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-5 py-4'>
          <div className='flex flex-col'>
            <select id="title" name="title" className='border border-[#0A235C] px-2 py-1'>
              <option value="">—Referred By—</option>
              <option value="Staffing">Staffing Partner</option>
              <option value="Social">Social Media</option>
              <option value="Internal">Internal</option>
            </select>
          </div>
          <div className='flex flex-col'>
            <input type="text" name="ReferenceSP" id="ReferenceSP" placeholder='Reference Staffing Partner' className='border border-[#0A235C] px-2 py-1 mb-2' />
          </div>
          <div className='flex flex-col'>
            <input type="text" name="ReferenceSM" id="ReferenceSM" placeholder='Reference Social Media' className='border border-[#0A235C] px-2 py-1 mb-2' />
          </div>
          <div className='flex flex-col'>
            <input type="text" name="ReferenceEC" id="ReferenceEC" placeholder='Reference Employee Code' className='border border-[#0A235C] px-2 py-1 mb-2' />
          </div>
          <div className='flex flex-col'>
            <select id="status" name="status" className='border border-[#0A235C] px-2 py-1'>
              <option value="">—Status—</option>
              <option value="Fresh-open">Fresh-open</option>
              <option value="Re-Open">Re-Open</option>
              <option value="Rejected">Rejected</option>
              <option value="Selected">Selected</option>
            </select>
          </div>
          <div className='flex flex-col'>
            <input type="text" name="lastPosition" id="lastPosition" placeholder='Last Organisation Name*' required className='border border-[#0A235C] px-2 py-1 mb-2' />
          </div>
        </div>
        <button type='submit' className='bg-white hover:bg-[#0A235C] text-[#0E4669] hover:text-white font-bold py-2 px-4 border border-[#0E4669] rounded-lg mt-3 mb-3 animated-button'>
          Submit
        </button>
      </form>
      {submitStatus && <p className="mt-4 text-center font-bold">{submitStatus}</p>}
    </section>
  );
}

export default CareerInfo;

